<template>
  <div>
    <div id="main">
      <div class="bg-other user-head-info">
        <div class="user-info clearfix">
          <div class="user-pic" data-is-fans="0" data-is-follows="">
            <div class="user-pic-bg"><img class="img" :src="cache.user.avatar" alt=""/></div>
          </div>
          <div class="user-info-right">
            <h3 class="user-name clearfix"><span>{{ cache.user.username }}</span></h3>
            <p class="about-info"><span>{{ cache.user.sex }}</span><a class="more-user-info"><i class="imv2-arrow2_d"></i>更多信息</a></p>
          </div>
          <div class="user-sign hide"><p class="user-desc">这位同学很懒，木有签名的说～</p></div>
          <div class="study-info clearfix">
            <div class="item follows"><div class="u-info-learn" title="学习时长2小时5分" style="cursor: pointer"><em>{{ cache.user.addbyid }}h</em><span>学习时长 </span></div></div>
            <div class="item follows"><a href="#/grxx" class="set-btn"><i class="icon-set"></i>个人设置</a></div>
          </div>
        </div>
      </div>
      <div class="wrap">
        <div class="slider" style="position: absolute; left: 24px; top: 0px">
          <ul>
            <li><a @click="act = '我的课程'" href="#" :class="{ active: act == '我的课程' }"><i class="imv2-play_circle"></i><span>我的课程</span><b class="icon-drop_right"></b></a></li>
            <li><a @click="act = '我的课表'" href="#" :class="{ active: act == '我的课表' }"><i class="new-icn"></i> <i class="imv2-list_bulleted"></i><span>我的课表</span><b class="icon-drop_right"></b></a></li>
            <li><a @click="act = '收藏'" href="#" :class="{ active: act == '收藏' }"><i class="imv2-star-revert"></i><span>收藏</span><b class="icon-drop_right"></b></a></li>
          </ul>
          <div id="personalLeft"></div>
        </div>
        <div class="u-container">
          <div class="c-tab clearfix">
            <div class="tool-left l"><a v-for="(v, i) in ['全部', '免费课', '实战课']" href="#" class="sort-item" @click="bct = v" :class="{ active: bct == v }" :key="i">{{ v }}</a></div>
          </div>
          <div class="all-course-main">
            <div class="allcourse-content js-course-list">
              <div v-for="(v, i) in cuscourselist" :key="i" class="courseitem tl-item">
                <div class="img-box"><a :href="`/video?courseid=${v.courseid}`" target="_blank"><img width="200" height="116" alt="如何从0到1，写出一份完美的PRD文档" :src="v.image"/></a></div>
                <div class="info-box course-list">
                  <div class="title"><span>{{ v.type }}</span><a :href="`/video?courseid=${v.courseid}`" class="hd" target="_blank">{{ v.coursename }}</a></div>

                 <!-- <div class="study-info"><span class="i-left span-common">已学{{ v.learningbfb }}%</span><span class="i-mid span-common">用时 {{ v.learninghour }}分钟</span><span class="i-right span-common">学习至{{ v.learningtoname }}</span></div>
                 -->

                  <div class="catog-points">
                   <!-- <span class="i-right span-common"><a :href="`/video?courseid=${v.courseid}`" target="_blank">问答 <i>{{ v.wd }}</i></a></span>-->
                    <a class="btn-red continute-btn" v-show="act == '收藏'" href="#" @click="cancel(v.courseid)">取消收藏</a>
                    <a v-show="act !== '收藏'" href="#/video?id=131" target="_blank" class="btn-red continute-btn">继续学习</a>
                  </div>
                  <div class="share-box clearfix course-one">
                    <div class="show-btn"><i class="icon-down2"></i></div>
                    <div class="share-box-con">
                      <div class="bdsharebuttonbox bdshare-button-style0-16">
                        <a href="javascript:;" title="删除" class="del"><i class="icon icon-notdisplay"></i></a>
                        <a class="bds_addFirst js-go-top js-placedTop-zhuge" data-cmd="" href="#" title="置顶课程" data-top="1"><i class="imv2-tuwen1"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../ulti/ajax";
import { mapState } from "vuex";
export default {
  data() {
    return {
      cuscourselist:[],

      act: "我的课程",
      bct: "全部",
    };
  },
  computed: {
    ...mapState(["cache"]),
  },

  async mounted() {
    await this.getdata();
    document.title='我的课程';
  },

  watch: {
    act:(newVal, oldVal)=>{
      this.renderData()
    },
    bct:()=>{
      this.renderData()
    }
  },
  methods: {



    getdata() {
      api.getMyCourse().then(response => {
         console.log("222:"+JSON.stringify(response))
        if (response.data.code == 200) {
          this.cuscourselist = response.data.rows;

          // console.log("222:"+JSON.stringify(this.data))
          //this.total = response.total;
        }
      });
    },




    // 取消收藏课程
  async  cancel(courseid) {
    let data = (await api.cancelCourseid(courseid)).data;
      this.renderData();
    },
    // 请求数据
  async  renderData(){
      // 请求数据重新渲染
     this.data = (await api.getgrzxData(this.act,this.btc)).data;
    }
  },
  created(){
    this.renderData()
  }
};
</script>

<style>
#main {
  padding: 0;
  position: relative;
}
.user-head-info .user-info {
  position: relative;
  width: 1152px;
  margin: 0 auto;
}

.user-head-info .user-info .user-pic {
  float: left;
  width: 148px;
  height: 148px;
}

.user-head-info .user-info .user-pic .user-pic-bg {
  border: 4px solid #fff;
  box-shadow: 0 4px 8px 0 rgba(7, 17, 27, 0.1);
  width: 140px;
  height: 140px;
  position: relative;
  border-radius: 50%;
  background: #fff;
  top: 24px;
}

.user-head-info .user-info .user-pic .user-pic-bg .img {
  text-align: center;
  width: 140px;
  height: 140px;
  border-radius: 50%;
}

.user-head-info .user-info .user-info-right {
  float: right;
  width: 980px;
}

.user-head-info .user-info .user-name {
  font-weight: 600;
  text-align: left;
  font-size: 24px;
  color: #fff;
  line-height: 28px;
  margin-top: 48px;
}

.user-head-info .user-info .user-name .signicon,
.user-head-info .user-info .user-name span {
  float: left;
}

.user-head-info .user-info .user-name .signicon {
  width: 25px;
  margin-left: 12px;
  background-image: url(https://static3.sycdn.imooc.com/static/img/u/user-info-icon.png);
  background-repeat: no-repeat;
  position: relative;
  top: 0;
}

.user-head-info .user-info .user-name .signicon_iden {
  width: 80px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
  background: #fff;
  display: none;
  position: absolute;
  top: 32px;
  left: -29px;
  color: #14191e;
  font-weight: 400;
}

.user-head-info .user-info .user-name .signicon_iden i {
  color: #fff;
  font-size: 32px;
  position: absolute;
  margin-left: -16px;
  left: 50%;
  top: -17px;
}

.user-head-info .user-info .user-name .teacher {
  background-position: 0 -24px;
  height: 25px;
}

.user-head-info .user-info .user-name .mxr {
  background-position: 0 2px;
  height: 24px;
  cursor: pointer;
}

.user-head-info .user-info .user-name .mxr .signicon_iden {
  width: 50px;
  left: -14px;
}

.user-head-info .user-info .user-name .author {
  background-position: 0 -48px;
  height: 24px;
}

.user-head-info .user-info .about-info {
  font-size: 14px;
  color: #fff;
  line-height: 20px;
  text-align: left;
  margin-top: 6px;
}

.user-head-info .user-info .about-info span {
  display: inline-block;
  margin-right: 10px;
  font-size: 14px;
  color: #fff;
  line-height: 20px;
}

.user-head-info .user-info .about-info a.more-user-info {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  line-height: 16px;
  display: inline-block;
  height: 16px;
  padding-left: 16px;
  border-left: 2px solid rgba(255, 255, 255, 0.4);
  cursor: pointer;
}

.user-head-info .user-info .about-info a.more-user-info i {
  display: inline-block;
  margin-right: 2px;
}

.user-head-info .user-info .about-info .gender {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(https://static3.sycdn.imooc.com/static/img/u/gender.png)
    no-repeat;
  vertical-align: -3px;
}

.user-head-info .user-info .about-info .sexSecret {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(https://static3.sycdn.imooc.com/static/img/u/secret.png)
    no-repeat;
  vertical-align: -3px;
}

.user-head-info .user-info .about-info .girl {
  background-position: 0 -17px;
}

.user-head-info .user-info .about-info .u-info-learn {
  margin-left: 10px;
}

.user-head-info .user-info .about-info .u-info-credit {
  margin-left: 10px;
}

.user-head-info .user-info .about-info .u-info-credit a {
  color: #fff;
}

.user-head-info .user-info .about-info .u-info-credit em {
  margin-left: 5px;
}

.user-head-info .user-info .about-info .u-info-mp {
  margin-left: 10px;
}

.user-head-info .user-info .about-info .u-info-mp a {
  color: #fff;
}

.user-head-info .user-info .about-info .u-info-mp em {
  margin-left: 5px;
}

.user-head-info .user-info .user-sign {
  font-size: 14px;
  color: #fff;
  line-height: 24px;
  width: 440px;
  overflow: hidden;
  word-break: break-all;
  word-wrap: break-word;
}

.user-head-info .user-info .user-desc {
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  text-align: left;
  margin-top: 20px;
  word-break: break-all;
  word-wrap: break-word;
  opacity: 0.5;
  margin-left: 24px;
}

.user-head-info .study-info {
  position: absolute;
  top: 48px;
  right: 10px;
  min-width: 200px;
  text-align: right;
}

.user-head-info .study-info .item {
  line-height: 48px;
  vertical-align: middle;
  height: 48px;
  float: left;
}

.user-head-info .study-info .item em {
  display: block;
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  color: rgba(255, 255, 255, 0.8);
  line-height: 28px;
}

.user-head-info .study-info .item span {
  display: block;
  text-align: center;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  line-height: 20px;
  margin-top: 4px;
}

.user-head-info .study-info .integral {
  margin: 0 40px;
}

.user-head-info .study-info .follows {
  margin-right: 24px;
}

.user-head-info .study-info .set-btn {
  padding: 8px 16px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 18px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  line-height: 20px;
  height: 20px;
}

.user-head-info .study-info .set-btn i {
  font-size: 16px;
  display: inline-block;
  margin-right: 4px;
}

.user-head-info .study-info .set-btn:hover {
  color: #fff;
  border-color: #fff;
}

.user-head-info .study-info .status-btn {
  width: 100px;
  padding: 8px 0;
  border-radius: 18px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  line-height: 20px;
  height: 20px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
}

.user-head-info .study-info .status-btn i {
  font-size: 16px;
  display: inline-block;
  margin-right: 4px;
}

.user-head-info .study-info .add-follow {
  background: rgba(240, 20, 20, 0.8);
  border-radius: 18px;
}

.user-head-info .study-info .add-follow:hover {
  background: #f01414;
}

.user-head-info .study-info .already-follow {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 18px;
}

.user-head-info .study-info .already-follow:hover {
  background: rgba(255, 255, 255, 0.4);
}

.user-head-info .study-info .js-fans-msg,
.user-head-info .study-info .js-u-add-follow,
.user-head-info .study-info .js-u-already-follow {
  margin-right: 10px;
}

.bg-other {
  background: url(https://static3.sycdn.imooc.com/static/img/u/temp1.png)
    no-repeat center top #000;
  background-size: cover;
}

.bg-author {
  background: url(https://static3.sycdn.imooc.com/static/img/u/temp1.png)
    no-repeat center top #000;
  background-size: cover;
}

.bg-teacher {
  background: url(https://static3.sycdn.imooc.com/static/img/u/temp1.png)
    no-repeat center top #000;
  background-size: cover;
}

.wrap {
  position: relative;
  width: 1200px;
  margin: 0 auto;
}

.slider {
  position: absolute;
  width: 172px;
  left: 24px;
  top: 0;
}

.slider ul li:first-child {
  margin-top: 40px;
}

.slider ul li {
  height: 48px;
  width: 160px;
  margin: 0 auto 8px;
}

.slider ul li:first-child a.active:after {
  display: block;
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 24px solid #f8fafc;
  border-bottom: 24px solid #f8fafc;
  border-left: 15px solid transparent;
}

.slider ul a {
  position: relative;
  display: block;
  height: 48px;
  font-size: 16px;
  line-height: 48px;
  text-align: center;
  color: #787d82;
  padding-left: 54px;
  text-align: left;
}

.slider ul a:hover {
  color: #14191e;
}

.slider ul a.active {
  color: #fff;
  background: rgba(240, 20, 20, 0.8);
  border-radius: 8px 0 0 8px;
}

.slider ul a.active i {
  color: #fff;
}

.slider ul a.active:after {
  display: block;
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 24px solid #fff;
  border-bottom: 24px solid #fff;
  border-left: 15px solid transparent;
}

.slider ul a i {
  position: absolute;
  left: 20px;
  top: 0;
  font-size: 20px;
  color: #93999f;
  line-height: 48px;
}

.slider ul a .new-icn {
  position: absolute;
  display: inline-block;
  top: 5px;
  left: 120px;
  width: 16px;
  height: 16px;
  background: url(https://static3.sycdn.imooc.com/static/img/common/new.png)
    no-repeat 0 0;
}

.slider ul a b {
  visibility: hidden;
}

.u-container {
  margin-left: 240px;
  min-height: 589px;
}

.addfriendtip {
  width: 417px;
  background: #c9c9c9;
  padding: 8px;
  display: none;
  position: absolute;
  z-index: 999;
  top: 30%;
  left: 215px;
}

.sendadd {
  width: 422px;
}

.sendadd textarea {
  height: 110px;
  border: 1px solid #cdcdcd;
  width: 382px;
  padding: 4px;
  background: #fff;
  font-size: 100%;
}

.sendadd textarea:focus {
  border-color: #b4b4b4;
}

.giveupcon h4,
.sendadd h4 {
  position: relative;
  font-size: 18px;
  font-family: "PingFang SC", "微软雅黑", "Microsoft YaHei", Helvetica,
    "Helvetica Neue", Tahoma, Arial, sans-serif;
  line-height: 40px;
  padding-left: 16px;
  height: 40px;
}

.giveupcon h4 span,
.sendadd h4 .tipclose {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
}

.sendcon {
  padding: 15px;
}

.sendsq {
  padding: 20px 0;
  text-align: center;
}

.successtip {
  width: 248px;
  background: #c9c9c9;
  padding: 6px;
  position: absolute;
  top: 37%;
  left: 280px;
  z-index: 999;
  display: none;
}

.successcon {
  width: 236px;
  padding: 6px;
  background: #fff;
  position: relative;
}

.successcon .suclose {
  background: url(https://static3.sycdn.imooc.com/static/img/tipicon.png)
    no-repeat 0 0;
  width: 16px;
  height: 16px;
  float: right;
  cursor: pointer;
}

.sendok {
  clear: both;
  background: url(https://static3.sycdn.imooc.com/static/img/zyicon.png)
    no-repeat 0 -106px;
  height: 52px;
  margin: 12px 0 0 30px;
  padding: 10px 0 0 50px;
  color: #666;
  font-size: 24px;
  font-family: "PingFang SC", "微软雅黑", "Microsoft YaHei", Helvetica,
    "Helvetica Neue", Tahoma, Arial, sans-serif;
}

.sendmsg_avatar {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-top: 8px;
  position: absolute;
  top: 0;
  left: 15px;
}

.ansListA {
  float: right;
  display: block;
  background: #fff;
}

.sendadd h4,
.sendmsg h4 {
  height: 56px;
  padding-left: 16px;
  position: relative;
  line-height: 56px;
  background: #be3948;
  color: #fff;
}

.sendmsg #sendEmojiIcon {
  margin: 0 0 0 7px;
  float: left;
}

.sendmsg #sendBtn {
  display: inline-block;
  height: 31px;
  color: #fff;
  text-align: center;
  line-height: 31px;
  font-size: 14px;
  font-weight: 700;
  float: right;
  padding: 0;
}

.sendmsg .imgset {
  float: left;
  width: 40px;
  height: 60px;
  background: url(https://static3.sycdn.imooc.com/static/img/icron_mgr.png)
    center 24px no-repeat;
}

.sendmsg_btn_box {
  background: #fff;
  padding: 12px 8px 20px;
  zoom: 1;
  position: relative;
  overflow: hidden;
}

.sendmsg_btn_box:after {
  content: "\20";
  display: block;
  clear: both;
}

.private-letter {
  width: 580px;
}

.private-letter .sendcon {
  background: #fff;
  padding: 20px 40px 10px;
  position: relative;
}

.private-letter textarea {
  width: 499px;
}

.private-letter .privatel-wrap {
  padding: 12px 0 20px 50px;
  color: #363d40;
}

.private-letter #sendEmojiIcon {
  margin-left: 40px;
}

.private-letter .sendmsg_btn_box {
  padding-right: 40px;
}

.pravtie_close .btn-large,
.private-letter #sendBtn {
  background: #39b94e;
}

.pravtie_close .btn-large:hover,
.private-letter #sendBtn:hover {
  background: #3ec154;
  color: #fff;
  outline: 0;
  -webkit-transition: 0.25s;
  -moz-transition: 0.25s;
  transition: 0.25s;
}

.pravtie_close .xubox_close1 {
  background: url(https://static3.sycdn.imooc.com/static/img/pravtie_close.jpg)
    0 0 no-repeat;
  width: 30px;
  height: 30px;
  top: 15px;
  right: 16px;
}

.pravtie_close .xubox_close1:hover {
  background-position: -30px 0;
}

.privatel-name {
  position: absolute;
  top: 19px;
  left: 40px;
}

.space-code .course-list li {
  height: 222px;
}

.space-code .course-list li .code-count {
  margin-top: 10px;
  font-size: 12px;
  color: #b5b9bc;
}

#personalLeft {
  display: none;
  width: 160px;
  height: 191px;
  position: absolute;
  left: -9px;
  top: 463px;
}

#personalLeft img {
  width: 100%;
}

.share-box {
  position: absolute;
  right: 10px;
  top: 27px;
  width: 30px;
  background-color: #ff0;
  overflow: visible;
}

.share-box .show-btn {
  position: absolute;
  top: 8px;
  right: 0;
  width: 30px;
  height: 20px;
  font-size: 18px;
  text-align: center;
  line-height: 20px;
  color: #bdc0c3;
  cursor: pointer;
}

.share-box .show-btn:hover i {
  color: #14191e;
}

.share-box .share-box-con {
  display: none;
  position: absolute;
  z-index: 99;
  top: 22px;
  right: 0;
  font-size: 20px;
  background-color: #fff;
  padding: 0 8px;
  box-shadow: 4px 4px 10px 2px #e1e1e1;
}

.share-box.hover .share-box-con {
  display: block;
}

.share-box .bdsharebuttonbox {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.share-box .bdsharebuttonbox a {
  display: inline-block;
  position: relative;
  font-weight: 400 !important;
  color: #b5b9bc !important;
  background-image: none !important;
  font-size: 20px;
  padding-left: 0;
  line-height: 40px;
  height: 40px;
  margin: 0;
  width: 40px;
  text-align: center;
}

.share-box .bdsharebuttonbox a:hover {
  color: #787d82 !important;
}

.share-box .follow {
  float: left;
  width: 40px;
  text-align: center;
  border-left: 1px solid #edf1f2;
  font-size: 20px;
  cursor: pointer;
}

.share-box .follow i:hover {
  color: #f01400;
}

.share-box .follow .icon-del {
  color: #b5b9bc;
  display: block;
}

.share-box .follow .icon-del:hover {
  color: #787d82;
}

.share-box .follow .icon-star_outline {
  color: #d8dadc;
}

.share-box .follow .icon-star2 {
  color: #f01400;
}

.qa-comment-page {
  margin-bottom: 58px;
}

.qa-comment-page .page {
  margin-bottom: 0;
}

.recomment-body {
  width: 100%;
  margin-top: 48px;
  padding-bottom: 40px;
}

.recomment-body h4 {
  font-size: 16px;
  color: #1c1f21;
  line-height: 18px;
}

.recomment-body ul {
  clear: both;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.recomment-body ul li {
  width: 216px;
  margin-bottom: 8px;
  margin-left: 32px;
}

.recomment-body ul li:first-child {
  margin-left: 0;
}

.recomment-body ul li:last-child .content {
  border-bottom: none;
}

.recomment-body ul li .recommend-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.recomment-body ul li .img {
  position: relative;
  margin-bottom: 8px;
}

.recomment-body ul li .img img {
  width: 216px;
  height: 120px;
  border: 0;
  border-radius: 8px;
}

.recomment-body ul li .content {
  width: 100%;
  text-align: left;
  box-sizing: border-box;
}

.recomment-body ul li .content p.title {
  margin-top: 0;
  font-size: 16px;
  color: #1c1f21;
  line-height: 22px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 4px;
  max-height: 44px;
  overflow: hidden;
}

.recomment-body ul li .content .info {
  font-size: 12px;
  color: #545c63;
  text-align: left;
  line-height: 18px;
}

.recomment-body ul li .content .info span {
  margin-right: 12px;
}

.recomment-body ul li .content .info span:first-child {
  font-weight: 700;
}

.recomment-body ul li .content .info span.imv2-set-sns {
  margin-right: 0;
}

.c-tab {
  font-size: 16px;
  color: #545c63;
  height: 52px;
  line-height: 52px;
  margin-top: 18px;
  border-bottom: 1px solid #d9dde1;
}

.c-tab .tool-left .active,
.c-tab .tool-left .sort-item:hover {
  color: #f20d0d;
  border-bottom: 2px solid #f20d0d;
}

.c-tab .sort-item {
  margin-right: 64px;
  display: inline-block;
  color: #545c63;
}

.c-tab .tool-item {
  cursor: pointer;
}

.c-tab .tool-all {
  position: relative;
  margin-right: 10px;
}

.c-tab .all-cont {
  width: auto;
  box-shadow: 0 2px 8px #bbb;
  max-height: 240px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #fff;
  position: absolute;
  z-index: 19881009;
  left: -30%;
  top: 55px;
}

.c-tab .all-cont li {
  height: 40px;
}

.c-tab .all-cont a {
  color: #787d82;
  line-height: 40px;
  white-space: nowrap;
  display: block;
  padding: 0 20px;
}

.c-tab .all-cont a:hover {
  background-color: #edf1f2;
  color: #14191e;
}

.c-tab .icon {
  margin-left: 15px;
  vertical-align: -1px;
}

.c-tab .icon-drop_up {
  color: #14191e;
}

.all-course-main {
  margin-top: 28px;
}

.allcourse-content {
  width: 100%;
  box-sizing: border-box;
}

.recommend-box-618 {
  width: 964px;
  height: 380px;
  margin: 30px auto 20px;
  border: 1px solid #f5f7fa;
  border-radius: 8px;
}

.recommend-box-618 .header {
  width: 949px;
  height: auto;
  background: #edeeef;
  border-radius: 8px 8px 0 0;
  padding: 13px 17px 15px 0;
  display: flex;
}

.recommend-box-618 .header .title {
  width: 880px;
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #545c63;
  text-align: left;
  font-weight: 400;
  margin-left: 23px;
  margin-right: 30px;
}

.recommend-box-618 .header .close-icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-size: 25px;
  cursor: pointer;
  color: #93999f;
}

.recommend-box-618 .body-class {
  width: 964px;
  height: 270px;
  margin: 24px auto 0;
}

.recommend-box-618 .body-class .swiper-container {
  padding-left: 39px;
  padding-right: 20px;
  height: 300px;
  background-color: #fff;
}

.recommend-box-618 .body-class .swiper-container .a-class-box {
  display: block;
  width: 270px;
  height: 270px;
  float: left;
  margin-right: 42px;
}

.recommend-box-618 .body-class .swiper-container .a-class-box .class-box {
  width: 270px;
  height: 270px;
  background-color: #fff;
  box-shadow: 0 6px 12px 0 rgba(55, 55, 55, 0.2);
  border-radius: 8px;
}

.recommend-box-618 .body-class .swiper-container .a-class-box .class-box img {
  display: block;
  width: 270px;
  height: 153px;
  border-radius: 8px 8px 0 0;
  cursor: pointer;
}

.recommend-box-618
  .body-class
  .swiper-container
  .a-class-box
  .class-box
  .class-title {
  width: 254px;
  height: 40px;
  line-height: 40px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #545c63;
  line-height: 20px;
  font-weight: 400;
  margin: 6px 0 7px 7px;
  overflow: hidden;
}

.recommend-box-618
  .body-class
  .swiper-container
  .a-class-box
  .class-box
  .difficulty {
  font-family: PingFangSC-Regular;
  font-size: 12px;
  line-height: 17px;
  color: #9199a1;
  font-weight: 400;
  margin: 0 0 10px 7px;
}

.recommend-box-618
  .body-class
  .swiper-container
  .a-class-box
  .class-box
  .price-box {
  width: 254px;
  font-family: PingFangSC-Semibold;
  font-size: 12px;
  color: #f01414;
  line-height: 18px;
  font-weight: 600;
  margin: 0 0 0 7px;
}

.recommend-box-618
  .body-class
  .swiper-container
  .a-class-box
  .class-box
  .price-box
  .price {
  float: left;
}

.recommend-box-618
  .body-class
  .swiper-container
  .a-class-box
  .class-box
  .price-box
  .shopping-cart {
  float: right;
  color: #ff655d;
  font-weight: 400;
  cursor: pointer;
}

.recommend-box-618
  .body-class
  .swiper-container
  .a-class-box
  .class-box
  .price-box
  span.money-icon {
  float: left;
}

.recommend-box-618 .body-class .swiper-btn {
  position: absolute;
  top: 471px;
  left: 248px;
  width: 24px;
  height: 33px;
  background-image: url(https://static3.sycdn.imooc.com/static/img/prev.png);
  background-size: cover;
  opacity: 1;
  cursor: pointer;
}

.recommend-box-618 .body-class .swiper-btn:hover {
  opacity: 0.5;
}

.recommend-box-618 .body-class .my-button-disabled {
  display: none;
}

.recommend-box-618 .body-class .swiper-btn.swiper-button-next {
  left: 1177px;
  background-image: url(https://static3.sycdn.imooc.com/static/img/next.png);
  background-size: cover;
}

.recommend-box-618 .body-class .a-class-box:last-child {
  margin-right: 0;
}

.courseitem {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 28px;
}

.courseitem:first-child {
  margin-top: 0;
}

.courseitem .img-box {
  width: 200px;
  margin-right: 30px;
}

.courseitem .img-box img {
  vertical-align: top;
}

.courseitem .img-box .pathimg {
  width: 116px;
  height: 116px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.courseitem .info-box {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #d9dde1;
  position: relative;
  padding-bottom: 28px;
  width: 730px;
}

.courseitem .info-box .title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.courseitem .info-box .title span:first-child {
  background: #f5f7fa;
  border-radius: 2px;
  width: 62px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #9199a1;
  font-weight: 400;
  margin-right: 12px;
}

.courseitem .info-box .title .hd {
  font-size: 20px;
  color: #07111b;
  font-weight: 700;
  line-height: 36px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.courseitem .info-box .title .i-new {
  margin-left: 12px;
  font-size: 14px;
  color: #4d555d;
  line-height: 24px;
}

.courseitem .info-box .study-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
}

.courseitem .info-box .study-info span {
  line-height: 24px;
  font-size: 14px;
  color: #4d555d;
  margin-right: 24px;
}

.courseitem .info-box .study-info span.i-left {
  color: #f20d0d;
}

.courseitem .info-box .study-info .follow-desc {
  font-size: 14px;
  color: #4d555d;
  line-height: 24px;
  width: 727px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.courseitem .info-box .follows-path {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  font-size: 12px;
  color: #9199a1;
  line-height: 18px;
}

.courseitem .info-box .follows-path i {
  font-style: normal;
  margin: 0 4px;
}

.courseitem .info-box .follows-path i.sz-star {
  display: inline-block;
  margin-top: -3px;
  font-size: 14px;
  vertical-align: middle;
}

.courseitem .info-box .follows-path .collectnum {
  margin-left: 33px;
}

.courseitem .info-box .op {
  text-align: right;
}

.courseitem .info-box .op .all_follow_f {
  font-size: 14px;
  color: #1c1f21;
  line-height: 20px;
}

.courseitem .info-box .op .all_follow_f:hover {
  color: #f20d0d;
}

.courseitem .info-box .catog-points {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.courseitem .info-box .catog-points .span-left {
  display: inline-block;
  margin-left: 38px;
}

.courseitem .info-box .catog-points span {
  font-size: 14px;
  line-height: 36px;
  color: #4d555d;
  margin-right: 36px;
}

.courseitem .info-box .catog-points span a {
  color: #4d555d;
}

.courseitem .info-box .catog-points span a:hover {
  color: #14191e;
}

.courseitem .info-box .catog-points span i {
  color: #93999f;
  font-style: normal;
}

.courseitem .info-box .catog-points span i.red {
  color: #f01400;
}

.courseitem .info-box .catog-points .continute-btn {
  display: inline-block;
  position: absolute;
  right: 0;
  font-size: 14px;
  border: none;
  color: #fff;
  width: 104px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(240, 20, 20, 0.6);
  border-radius: 18px;
}

.courseitem .info-box .catog-points .continute-btn:hover {
  background-color: #f01414;
  color: #fff;
}

.courseitem .info-box .path-course {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 14px;
  color: #9199a1;
  line-height: 20px;
  margin-top: 12px;
}

.courseitem .info-box .path-course span {
  margin-right: 6px;
  display: inline-block;
}

.courseitem .info-box .path-course a.linkurl {
  margin-right: 24px;
  color: #37f;
  text-decoration: underline;
}

.courseitem .info-box .share-box .show-btn {
  top: -20px;
}

.courseitem .info-box .active-red {
  color: red;
}

.courseitem .info-box .share-box-con {
  width: auto;
  top: 0;
  height: auto;
}

.courseitem.overdue {
  opacity: 0.6;
}

.courseitem.overdue .catog-points .del-box {
  margin-left: auto;
}

.not-conts {
  font-size: 18px;
  color: #b5b9bc;
  text-align: center;
  padding: 145px 0 100px;
}

.not-conts .find-course {
  color: #f01400;
}
</style>
